import type { SeoData } from '~/typesAuto/saga/v1'
import type { SiteConfig } from '~/typesManual/config/site'

export type ExtraMetaContent = {
  type?: string
  description?: string
  author?: string
  image?: string
  url?: string
  section?: string
  publishedTime?: string
  modifiedTime?: string
  rawMetaTitle?: string
  rawMetaDescription?: string
}

type MetaObjectSimple = {
  title: string
  meta: {
    hid: string
    name?: string
    property?: string
    content?: string
  }[]
  link?: {
    rel: string
    href: string
  }[]
}

export const createBasicHeadMetaData = (
  siteConfig: {
    [key: string]: unknown
  },
  requestUrl: URL
): MetaObjectSimple => {
  const typedSiteConfig = siteConfig as SiteConfig

  let imageToUse = ''
  if (typedSiteConfig.identifier === 'mm_dk') {
    imageToUse = `${requestUrl.origin}/defaultOgImageMm.png`
  } else {
    imageToUse = `${requestUrl.origin}/defaultOgImageAltinget.png`
  }

  const newHeadData: MetaObjectSimple = {
    title: typedSiteConfig.meta.defaultTitle,
    meta: [
      {
        hid: 'twitter:card',
        name: 'twitter:card',
        content: `summary_large_image`,
      },
      {
        hid: 'twitter:site',
        name: 'twitter:site',
        content: `@${typedSiteConfig.twitter}`,
      },
      {
        hid: 'twitter:image',
        name: 'twitter:image',
        content: imageToUse,
      },
      {
        hid: 'twitter:title',
        name: 'twitter:title',
        content: typedSiteConfig.meta.defaultTitle,
      },
      {
        hid: 'og:image',
        property: 'og:image',
        content: imageToUse,
      },
      {
        hid: 'og:title',
        property: 'og:title',
        content: typedSiteConfig.meta.defaultTitle,
      },
      {
        hid: 'og:locale',
        property: 'og:locale',
        content: typedSiteConfig.localeCode,
      },
      {
        hid: 'og:type',
        property: 'og:type',
        content: 'website',
      },
      {
        hid: 'og:url',
        property: 'og:url',
        content: `${requestUrl.href}`,
      },
    ],
  }

  return newHeadData
}

export const createHeadMetaData = (
  siteConfig: { [key: string]: unknown },
  requestUrl: URL,
  title?: string,
  extraMetaContent?: ExtraMetaContent,
  ignorePostFixTitle?: boolean
): MetaObjectSimple => {
  const typedSiteConfig = siteConfig as SiteConfig

  let imageToUse: string | undefined = undefined
  /* 
    If extraMetaContent has an image, use that
    If not, use the default image for the site
  */
  if (extraMetaContent?.image) {
    imageToUse = extraMetaContent.image
  } else if (typedSiteConfig.identifier === 'mm_dk') {
    imageToUse = `${requestUrl.origin}/defaultOgImageMm.png`
  } else {
    imageToUse = `${requestUrl.origin}/defaultOgImageAltinget.png`
  }

  const defaultTitle = typedSiteConfig.meta.defaultTitle
  let usedTitle = ''
  if (title && !ignorePostFixTitle) {
    usedTitle = `${title} - ${defaultTitle}`
  } else if (title) {
    usedTitle = title
  } else {
    usedTitle = defaultTitle
  }

  const metaTitle = extraMetaContent?.rawMetaTitle ?? usedTitle

  const newHeadData: MetaObjectSimple = {
    title: usedTitle,
    meta: [
      {
        hid: 'twitter:card',
        name: 'twitter:card',
        content: `summary_large_image`,
      },
      {
        hid: 'twitter:site',
        name: 'twitter:site',
        content: `@${typedSiteConfig.twitter}`,
      },
      {
        hid: 'twitter:title',
        name: 'twitter:title',
        content: metaTitle,
      },
      {
        hid: 'twitter:image',
        name: 'twitter:image',
        content: imageToUse,
      },
      {
        hid: 'og:title',
        property: 'og:title',
        content: metaTitle,
      },
      {
        hid: 'og:image',
        property: 'og:image',
        content: imageToUse,
      },
      {
        hid: 'og:locale',
        property: 'og:locale',
        content: typedSiteConfig.localeCode,
      },
    ],
  }

  // ts fix
  if (!newHeadData.meta) {
    return {} as MetaObjectSimple
  }

  if (extraMetaContent) {
    if (extraMetaContent.description) {
      let metaDescription = extraMetaContent.description
      if (extraMetaContent.rawMetaDescription) {
        metaDescription = extraMetaContent.rawMetaDescription
      }

      newHeadData.meta.push(
        ...[
          {
            hid: 'description',
            property: 'description',
            content: extraMetaContent.description,
          },
          {
            hid: 'og:description',
            property: 'og:description',
            content: metaDescription,
          },
          {
            hid: 'twitter:description',
            name: 'twitter:description',
            content: metaDescription,
          },
        ]
      )
    }
    if (extraMetaContent.type === 'article') {
      newHeadData.meta.push(
        ...[
          { hid: 'og:type', property: 'og:type', content: 'article' },
          {
            hid: 'article:section',
            name: 'article:section',
            content: extraMetaContent.section,
          },
          {
            hid: 'article:published_time',
            name: 'article:published_time',
            content: extraMetaContent.publishedTime,
          },
        ]
      )

      if (extraMetaContent.author) {
        newHeadData.meta.push({
          hid: 'article:author',
          name: 'article:author',
          content: extraMetaContent.author,
        })
      }

      if (extraMetaContent.modifiedTime) {
        newHeadData.meta.push({
          hid: 'article:modified_time',
          name: 'article:modified_time',
          content: extraMetaContent.modifiedTime,
        })
      }
    }
  } else {
    newHeadData.meta.push(
      ...[
        {
          hid: 'og:type',
          property: 'og:type',
          content: 'website',
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: `${requestUrl.href}`,
        },
      ]
    )
  }

  return newHeadData
}

export const createHeadMetaDataWithSeoData = (
  siteConfig: SiteConfig,
  requestUrl: URL,
  routeName: string,
  currentPaperName: string,
  seoData: SeoData | undefined
) => {
  const defaultMetaData = getMetaDataSpec(
    siteConfig,
    routeName,
    currentPaperName
  )

  let usedTitle = defaultMetaData.title
  const extraMetaContent: ExtraMetaContent = {
    section: currentPaperName,
    description: defaultMetaData.description,
  }

  // Overwrite with SEO data if it exists
  if (seoData) {
    if (seoData.title) {
      usedTitle = seoData.title
    }
    if (seoData.description) {
      extraMetaContent.description = seoData.description
    }
    if (seoData.image) {
      extraMetaContent.image = seoData.image
    }
  }

  const metadata = createHeadMetaData(
    siteConfig,
    requestUrl,
    usedTitle,
    extraMetaContent,
    true
  )

  return metadata
}

export const createDefaultHeadMetaData = (
  siteConfig: SiteConfig,
  requestUrl: URL,
  routeName: string,
  currentPaperName: string
) => {
  const boundMetaData = getMetaDataSpec(siteConfig, routeName, currentPaperName)

  const extraMetaContent: ExtraMetaContent = {
    section: currentPaperName,
    description: boundMetaData.description,
  }

  const metadata = createHeadMetaData(
    siteConfig,
    requestUrl,
    boundMetaData.title,
    extraMetaContent,
    true
  )

  return metadata
}

export const getMetaDataSpec = (
  siteConfig: SiteConfig,
  routeName: string,
  currentPaperName: string
) => {
  /* DK START */
  const dkMetaDataLookup: {
    [key: string]: { title: string; description: string }
  } = {
    ads: {
      title: 'Annoncer | Altinget og Mandag Morgen',
      description:
        'Nå ud til relevante beslutningstagere og meningsdannere. Annoncér hos Altinget og Mandag Morgen. Læs mere om vores målgrupper og annonceprodukter her.',
    },
    conferences: {
      title:
        'Altinget Live arrangementer | Nyheder, analyser og debat om dansk politik',
      description:
        'Altinget og Mandag Morgen afholder konferencer, debatter og morgenmøder i det centrale København. Se vores aktuelle arrangementer og tilmeld dig her.',
    },
    courses: {
      title: 'Altinget Kurser | Se alle vores kurser her',
      description:
        'Altinget og Mandag Morgen tilbyder kurser til fagfolk og beslutningstagere, der giver ny faglig viden og skarp politisk indsigt til dig og din organisation. Sammen med professionelle aktører inden for netop dit fagområde får du inspiration, viden og redskaber fra højtkvalificerede undervisere og unikke indblik i de politiske beslutningsprocesser.',
    },
    debate: {
      title: 'Altinget Debat | Aktuelle politiske debatter og kommentarer',
      description:
        'Altinget bringer indlæg, kommentarer og klummer om aktuelle og relevante emner i samfundsdebatten.',
    },
    jobs: {
      title: 'Altinget Job',
      description: siteConfig.meta.defaultDescription,
    },
    facilities: {
      title:
        'Altinget Partnerskaber og Events | Nyheder, analyser og debat om dansk politik',
      description:
        'Lad Altinget sætte scenen for dig. Book vores gårdscene for fysiske arrangementer, eller lej et lyd- eller webcaststudie til din næste podcast eller udsendelse.',
    },
    news: {
      title: 'Altinget Nyheder | Seneste nyt',
      description: 'Få de seneste nyheder, analyser og indsigter fra Altinget.',
    },
    newsletter: {
      title: 'Altinget nyhedsbrev | Få daglige nyheder fra Altinget',
      description:
        'Tilmeld dig Altingets nyhedsbrev. Så får du to gange dagligt aktuelle nyheder og skarpe holdninger fra nogle af landets bedste skribenter.',
    },
    'names-index': {
      title: 'Altinget Navnenyt | De vigtigste navnenyheder og jobskifter ',
      description: 'Politik handler om mennesker - få overblik over dem her. ',
    },
    papers: {
      title:
        'Altinget nichemedier | Find det nichemedie, som dækker dit fagområde',
      description:
        'Vores nichemedier går i substansen og giver nøglemedarbejdere adgang til grundige analyser, politiske nyheder og vigtig indsigt indenfor netop jeres felt',
    },
    paper: {
      title: `Altinget ${currentPaperName}`,
      description: siteConfig.meta.defaultDescription,
    },
    paper_subject: {
      title: `Altinget ${currentPaperName} | Emne`,
      description: siteConfig.meta.defaultDescription,
    },
    'paper_names-index': {
      title: `Altinget ${currentPaperName} | Navnenyt`,
      description: 'Politik handler om mennesker - få overblik over dem her.',
    },
    paper_debate: {
      title: `Altinget ${currentPaperName} | Debat`,
      description:
        'Altinget bringer indlæg, kommentarer og klummer om aktuelle og relevante emner i samfundsdebatten.',
    },
    paper_news: {
      title: `Altinget ${currentPaperName} | Nyheder`,
      description: 'Få de seneste nyheder, analyser og indsigter fra Altinget.',
    },
    'paper_qa-index': {
      title: `Altinget ${currentPaperName}`,
      description: siteConfig.meta.defaultDescription,
    },

    networks: {
      title: 'Altinget Netværk | Nyheder, analyser og debat om dansk politik',
      description:
        'Altingets fagpolitiske netværk giver dig mulighed for at erfaringsudveklse med nøglepersoner inden for dit område. Se alle vores netværksgrupper her.',
    },
    ssoLogin: {
      title: 'Log på med Single Sign-On',
      description: siteConfig.meta.defaultDescription,
    },
    subscription: {
      title: 'Abonnement på Altinget Privat',
      description:
        'Når du læser Altinget, får du politisk journalistik, der ikke vælger side. Tegn et abonnement på Altinget Privat her.',
    },
    yearbook: {
      title:
        'Læs Altingets jubilæumsbog, der fortæller historien om Altinget fra 2000 til 2020.',
      description: 'Altinget jubilæumsbog',
    },
    'yearbook-index': {
      title:
        'Læs Altingets jubilæumsbog, der fortæller historien om Altinget fra 2000 til 2020.',
      description: 'Altinget jubilæumsbog',
    },
  }
  /* DK DONE */

  /* SE START */
  const seMetaDataLookup: {
    [key: string]: { title: string; description: string }
  } = {
    ads: {
      title:
        'Altinget Annonser | Nyheter, analyser och debatt om svensk politik',
      description:
        'Annonsera hos Altinget och nå ut till relevanta beslutsfattare och opinionsbildare. Läs mer om våra målgrupper och annonsprodukter här.',
    },
    debate: {
      title: 'Altinget Debatt | Aktuella politiska debatter och analyser',
      description:
        'Ta del av debatt kring politiken, lagstiftningen och utredningarna i Sverige. Altinget lägger stor vikt vid att ha en debatt som fokuserar på sakpolitiken.',
    },
    courses: {
      title: 'Altinget Kurser | Kompetensutveckling inom politik och policy',
      description:
        'Utveckla din kunskap om politik och samhällsfrågor med Altingets kurser. Lär av experter och stärk din kompetens.',
    },
    jobs: {
      title: 'Altinget Jobb | Lediga tjänsterna inom ditt område',
      description:
        'Hitta din nya medarbetare på Altinget. Karriär och relevanta lediga tjänster.',
    },
    decisionchain: {
      title: 'Altinget beslutskedjan | Håll koll på lagstiftningen',
      description:
        'Från idé till beslut. Håll koll på lagstiftningen med hjälp av beslutskedjan. Ett verktyg för dig som jobbar med politik och påverkan.',
    },
    decisionchain_id: {
      title: 'Altinget beslutskedjan | Håll koll på lagstiftningen',
      description:
        'Från idé till beslut. Håll koll på lagstiftningen med hjälp av beslutskedjan. Ett verktyg för dig som jobbar med politik och påverkan.',
    },
    'names-index': {
      title: 'Altinget Namn | Namnnyheter',
      description:
        'Politik är personer - håll koll på dem. Här hittar du namnnyheter.',
    },
    networks: {
      title: 'Altinget Nätverk | Fördjupa dig i samhällsfrågor och politik',
      description:
        'Gå med i Altingets sakpolitiska nätverk för att utbyta erfarenheter och skapa värdefulla kontakter inom ditt intresseområde.',
    },
    news: {
      title: 'Altinget Nyheter | Senasta artiklarna',
      description: 'Ta del av de senaste nyheterna från Altinget.',
    },
    newsletter: {
      title: 'Altinget nyhetsbrev | Få en daglig politisk överblick',
      description:
        'Med vårt nyhetsbrev får du en daglig politisk överblick med nyheter, debatt och aktuella personnyheter. Gratis och utan bindning. Registrera dig idag.',
    },
    paper: {
      title: `Altinget ${currentPaperName}`,
      description: siteConfig.meta.defaultDescription,
    },
    paper_subject: {
      title: `Altinget ${currentPaperName} | Emne`,
      description: siteConfig.meta.defaultDescription,
    },
    paper_debate: {
      title: `Altinget ${currentPaperName} | Debatt`,
      description:
        'Ta del av debatt kring politiken, lagstiftningen och utredningarna i Sverige. Altinget lägger stor vikt vid att ha en debatt som fokuserar på sakpolitiken.',
    },
    paper_news: {
      title: `Altinget ${currentPaperName} | Nyheter`,
      description: 'Ta del av de senaste nyheterna från Altinget.',
    },
    'paper_names-index': {
      title: `Altinget ${currentPaperName} | Namnnyheter`,
      description:
        'Politik är personer - håll koll på dem. Här hittar du namnnyheter.',
    },
    ssoLogin: {
      title: 'Logga in med enkel inloggning',
      description:
        'Om din arbetsgivare aktiverat enkel inloggning kan du logga in här med ditt Microsoft-konto',
    },
    subscription: {
      title: 'Altinget prenumeration | Provläs Altinget gratis i 14 dagar',
      description:
        'Få tillgång till ett eller flera av våra 11 nischområden som ger dig nyheter, analyser och debatt om svensk sakpolitik. Vi tar politik på allvar.',
    },
  }
  /* SE DONE */

  /* NO START */
  const noMetaDataLookup: {
    [key: string]: { title: string; description: string }
  } = {
    ads: {
      title: 'Annonsere med Altinget',
      description:
        'Altinget har en attraktiv og kjøpesterk målgruppe. Altingets lesere er høyt utdannede, samfunnsinteresserte og ressurssterke på mange områder.',
    },
    news: {
      title: 'Altinget Nyheter | Nyeste artiklene',
      description: 'Hold deg oppdatert med de nyeste artiklene fra Altinget.',
    },
    debate: {
      title: 'Altinget Debatt | Din Arena for Samfunnsmeninger',
      description:
        'Ta del i debatten om politikken, lovgivningen og utredningene i Norge.',
    },
    ssoLogin: {
      title: 'Logg på med Single Sign-On',
      description: siteConfig.meta.defaultDescription,
    },
    pricing: {
      title: 'Altinget abonnement | Les Altinget gratis i 14 dager',
      description:
        'Få tilgang til et eller fler av våre nisjeområder som gir deg nyheter, analyser och debatt om norsk politikk. Politikk har aldri vært viktigere.',
    },
    receipt: {
      title: `Kvittering | ${siteConfig.meta.defaultTitle}`,
      description: siteConfig.meta.defaultDescription,
    },
    'job-names': {
      title: 'Altinget Jobb og navn | Navnenyhetert og ledige stillinger',
      description:
        'Politikk handler om mennesker - hold oversikt over dem. Finn din nye medarbeider på Altinget. Her finner du navnenyheter og relevante ledige stillinger. Utforsk ledige jobber innen politikk, samfunn og offentlig sektor. Finn din neste karrieremulighet på Altinget.',
    },
    newsletter: {
      title: 'Altinget nyhetsbrev | Få et daglig politisk overblikk',
      description:
        'Med Altinget nyhetsbrev får du et daglig politisk overblikk med nyheter, debatt og aktuell navnenytt. Gratis og uten bindningstid. Registrer deg i dag.',
    },
    papers: {
      title:
        'Dette er Altingets fagpolitiske nisjer | Finn nisjen som følger ditt fagområde',
      description:
        'Altinget dekker seks politiske fagområder. Les politiske nyheter om løvebakke, forsvar, helse, klima og energi, EU og EØS og arbeidsliv.',
    },
    paper: {
      title: `Altinget ${currentPaperName}`,
      description: siteConfig.meta.defaultDescription,
    },
    paper_subject: {
      title: `Altinget ${currentPaperName} | Emne`,
      description: siteConfig.meta.defaultDescription,
    },
    paper_news: {
      title: `Altinget ${currentPaperName} | Nyheter`,
      description: 'Ta del av de senaste nyheterna från Altinget.',
    },
    paper_debate: {
      title: `Altinget ${currentPaperName} | Debatt`,
      description:
        'Ta del i debatten om politikken, lovgivningen og utredningene i Norge.',
    },
    'paper_job-names': {
      title: `Altinget ${currentPaperName} | Jobb og navn`,
      description:
        'Politikk handler om mennesker - hold oversikt over dem. Finn din nye medarbeider på Altinget. Her finner du navnenyheter og relevante ledige stillinger. Utforsk ledige jobber innen politikk, samfunn og offentlig sektor. Finn din neste karrieremulighet på Altinget.',
    },
    'paper_qa-index': {
      title: `Altinget ${currentPaperName}`,
      description: siteConfig.meta.defaultDescription,
    },
  }
  /* NO END */

  /* MM START */
  const mmMetaDataLookup: {
    [key: string]: { title: string; description: string }
  } = {
    ssoLogin: {
      title: 'Log på med Single Sign-On',
      description: siteConfig.meta.defaultDescription,
    },
    subscription: {
      title: 'Abonnement',
      description: siteConfig.meta.defaultDescription,
    },
    subscription_type: {
      title: 'Abonnement',
      description: siteConfig.meta.defaultDescription,
    },
  }
  /* MM DONE */

  if (siteConfig.identifier === 'altinget_dk' && dkMetaDataLookup[routeName]) {
    return dkMetaDataLookup[routeName]
  } else if (
    siteConfig.identifier === 'altinget_se' &&
    seMetaDataLookup[routeName]
  ) {
    return seMetaDataLookup[routeName]
  } else if (
    siteConfig.identifier === 'altinget_no' &&
    noMetaDataLookup[routeName]
  ) {
    return noMetaDataLookup[routeName]
  } else if (siteConfig.identifier === 'mm_dk' && mmMetaDataLookup[routeName]) {
    return mmMetaDataLookup[routeName]
  }

  // Default
  return {
    title: siteConfig.meta.defaultTitle,
    description: siteConfig.meta.defaultDescription,
  }
}
